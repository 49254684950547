import './mod/avif-webp'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Typewriter from 'typewriter-effect/dist/core'
import {animaCounter} from 'anima-counters'
import signature from 'console-signature'
import Swiper, { Navigation } from 'swiper'
import './mod/contactForm7'

Swiper.use([Navigation])

;(function ($) {
    ;('use strict')

    /** Global vars defined by functions.php with 'wp_localize_script' @ script enqueue   **/
    var global = pct_globalVars
    signature()

    const winDim = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    window.addEventListener('load', (event) => {
        if(pct_globalVars.sticky_menu){
            stickyMenu()
        }
        // home_slider_typewrite()
    })
    /**----------------------- END ONLOAD SECTION ----------------------------------------------------------*/


    /*// ! Make sure wp-block-cover elements has img as bg wrapped in figure tag */
    const wrapElement = (el, wrapper) => {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }


    document.querySelectorAll('.wp-block-cover > img').forEach( el => {
        wrapElement(el, document.createElement('figure'))
        // example: wrapping an anchor with class "wrap_me" into a new div element
        // wrap(document.querySelector('div.wrap_me'), document.createElement('figure'));
    })


    /*// ! prepare gutenberg dom classnames for swiper */
    const swiperTarget = '.mySwiper'


    document.querySelectorAll(swiperTarget).forEach( el => {
        const swiperWrapper = el.firstChild;
        swiperWrapper.classList.add('swiper-wrapper')

        // Add navigation elements
        const btnext = document.createElement('div')
        btnext.classList.add('swiper-button-next')
        el.appendChild(btnext)
        const btprev = document.createElement('div')
        btprev.classList.add('swiper-button-prev')
        el.appendChild(btprev)


        let elChildren = [...swiperWrapper.children]

        elChildren.forEach( el => {
            if(!el.classList.contains('swiper-button-prev') && !el.classList.contains('swiper-button-next') )
                el.classList.add('swiper-slide')
        })
    })


    /*// ? init Swiper: */
    const swiper = new Swiper('.swiper', {
        // configure Swiper to use modules
        modules: [Navigation],
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
    });


    /*// ? animacounters */

    document.querySelectorAll('.pct-counter span').forEach(el => {
        let options = {
            duration: 500,
            start: -50,
            duration: 200,
            delay: 1150,
            effect: 'scroll'
        }
        // Create the object
        let newAnimaCounter = new animaCounter(el, options)
    })



    document.querySelectorAll('.typewrited').forEach((el) => {
        // console.log(el);
        const elID = el.getAttribute('id')
        const strings = eval(elID);

        el.typewrite = new Typewriter(`#${elID}`,{
          loop: true,
          delay: 'natural',
          cursor: '_',
          deleteSpeed: 10
        })

        el.typewrite
            .pauseFor(1000)
            .typeString(strings[0])
            .pauseFor(3000)
            .deleteChars(strings[0].length)
            .typeString(strings[1] || strings[0])
            .pauseFor(3000)
            .deleteChars(strings[1] ? strings[1].length : strings[0].length)
            .start()
    })




    // ! Check scroll position to handle sticky menu display

    let stickyMenu = () => {
        const menu = document.getElementById('masthead');
        const menuHeight = menu.offsetHeight;

        if(menu){
            /** Duplicate menu node to create a sticky replica */
            let stickyMenu = menu.cloneNode(true)
            stickyMenu.setAttribute('id', 'sticky_menu')
            menu.before(stickyMenu);

            var observer = new IntersectionObserver(function(entries) {
                if(entries[0].isIntersecting === true){
                    stickyMenu.classList.remove('show')
                }
                else {
                    stickyMenu.classList.add('show')
                }
            }, { threshold: [0] });

            observer.observe(menu);


        }
    }


    /*------------------------------------------------------------------------------------------------------*
        Mobible menu show / hide
    \*------------------------------------------------------------------------------------------------------*/
    // const mopener = document.getElementById('primary-mobile-menu');
    // console.log(mopener);

    // document.getElementById('primary-mobile-menu').addEventListener('click', (e) => {
    //     console.log('clicado',e);
    // })


    /*------------------------------------------------------------------------------------------------------*
                            PARALLAX
    \*------------------------------------------------------------------------------------------------------*/
    gsap.registerPlugin(ScrollTrigger)

    if (document.querySelector('.pct-parallax.wp-block-cover')) {
        // Set any <figure> tag with class '.pct-is-background' as background-image of its parent width class '.pct-parallax-bg'
        document.querySelectorAll('.pct-parallax.wp-block-cover').forEach((el) => {
            const imgEl = el.querySelector('figure img')
            imgEl.style.cssText += 'width:100%; position:absolute; left:50%; transform:translate3d(0%,-50%,0); object-fit: cover;'
        })

        const enteredViewportNew = () => {
            console.log('triggered!!')
        }


        //! Parallax background
        gsap.utils.toArray('.pct-parallax.wp-block-cover').forEach((container) => {

            const movement = -(container.offsetHeight * container.dataset.parallaxspeed)
            const target = container.querySelector('figure img')

            // console.log(container.dataset.parallaxspeed, target);


            gsap.to(target, {
                objectPosition: 'center ' +  movement + 'px',
                ease: 'none',
                scrollTrigger: {
                    trigger: container,
                    start: 'top bottom',
                    end: 'bottom top',
                    scrub: true,
                    // onEnter: enteredViewportNew,
                },
            })
        })
    }



    if (document.querySelectorAll('.pct-header-slider').length) {
    // console.log('hay slider de cabecera de página')
        gsap.utils.toArray('.pct-header-slider').forEach((container) => {
            gsap.to(container, {
                y: parseInt(container.offsetHeight * 0.3),
                // y: container.offsetHeight * 0.3,
                ease: 'none',
                scrollTrigger: {
                    trigger: container,
                    start:
                        winDim().width < 980
                            ? 'top ' + document.querySelector('#ast-mobile-header').offsetHeight
                            : 'top top', // document.querySelector('#ast-mobile-header').offsetHeight ---> for mobile
                    end: 'bottom top',
                    scrub: true,
                    // onUpdate: updatingST,
                },
            })
        })
    }

     /**
     * Customize language switcher
     */

    const switcherContDom = document.querySelectorAll(
        '#lang_switcher'
    )
    const menuLangSwitcherDom = document.querySelectorAll(
        '#lang_switcher .lang-item > a'
    )


    menuLangSwitcherDom.forEach((el,index) => {
        switch (el.getAttribute('hreflang')) {
            case 'es-ES':
                el.setAttribute('title', 'Español')
                break

            case 'en-GB':
                el.setAttribute('title', 'English')
                break
        }

        // Adding a separator element between languages
        if(index) {
            let separatorNode = document.createElement('li')
            separatorNode.classList.add('lang-separator')
            let separatorTxt = document.createTextNode('/')
            separatorNode.appendChild(separatorTxt)
            el.parentNode.before(separatorNode);
        }

    })

    menuLangSwitcherDom.forEach((el) => {
        el.text = el.text.substring(0, 2)
    })

    switcherContDom.forEach((el) => {
        el.classList.add('enabled')
    })

    // switcherContDom.classList.add('enabled')

    /**
       //!AVIF BACKGROUND IMAGES BY CLASS SELECTOR
     *  if not AVIF capabilities, then use a .jpg instead
     * AVIF capabilities checked by avif-webp.js, adding a webp/avif class to <html> tag.
     */
    ;[].slice.call(document.querySelectorAll('[class*="pct-avif-bg-"]')).forEach(function (el) {
        var $el = $(el)
        var fileName = $el
            .attr('class')
            .match(/pct-avif-bg-(.*)+/)[0]
            .split(' ')[0]
            .split('pct-avif-bg-')[1]
        var filePath = global.mediaURL + '/' + fileName + '.' + ($('html').hasClass('avif') ? 'avif' : 'jpg')

        $el.prepend('<div class="pct-bgimg"></div>')
        var $bg = $el.find('.pct-bgimg')
        $bg.css({
            'background-image': 'url(' + filePath + ')',
            'background-size': 'cover',
            'position': 'absolute',
            'top': '0',
            'left': '0',
            'width': '100%',
            'height': '100%',
        })
    })

    // ? SPECIAL TAG ATTRIBUTES
    document.querySelectorAll('[data-maxwidth]').forEach(el =>{
        el.style.cssText += `max-width:${el.dataset.maxwidth}px`
    })


})(jQuery)
